import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';

import { useDebounce } from '@vk-hr-tek/core/hooks';

import { CancelIcon } from '../../icons';
import { Label, Preloader } from '../common';
import { useAutocompleteReducer } from '../hooks';
import { Filter } from '../types';

import useStyles from './SuggestedInput.styles';
import { SuggestedInputProps } from './SuggestedInput.types';
import { ListBox } from './ListBox';
import { TextFieldMasked } from './TextFieldMasked';

const DEBOUNCE_TIMEOUT = 200;

export const SuggestedInput = (props: SuggestedInputProps) => {
  const {
    name,
    label,
    value,
    onChange,
    onBlur,
    placeholder,
    disabled = false,
    required = false,
    clearable = false,
    loading = false,
    tooltip,
    error = false,
    helperText,
    renderOption,
    limit = 50,
    minInputValueLength = 1,
    mask,
  } = props;

  const classes = useStyles();

  const { items, status, hasMore, requestItems, resetItems } =
    useAutocompleteReducer<string>(
      props.type === 'load' ? props.loadItems : undefined,
    );

  const [isLoading, setLoading] = useState(false);
  const [filter, setFilter] = useState<Filter>({
    limit,
    offset: 0,
    query: '',
  });
  const [open, setOpen] = useState(false);

  const isItemsLoading = isLoading || status === 'loading';
  const debouncedInputValue = useDebounce(value, DEBOUNCE_TIMEOUT) ?? '';

  const handleChange = useCallback(
    (event: ChangeEvent<Record<string, unknown>>, nextValue: string | null) => {
      onChange(nextValue ?? undefined);

      if (props.type === 'load') {
        resetItems();
        setFilter({ ...filter, offset: 0, query: '' });
      }

      setOpen(false);
    },
    [onChange, filter, props.type, resetItems],
  );

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<Record<string, unknown>>,
      nextValue: string | null,
      reason,
    ) => {
      onChange(nextValue ?? undefined);

      if (reason === 'reset') return;

      if (nextValue?.replace(/_+/g, '').trim()) {
        if (!open) {
          setOpen(true);
        }
      } else {
        setOpen(false);
      }
    },
    [onChange, open],
  );

  const handleBlur = useCallback(() => {
    onBlur?.();
    if (open) setOpen(false);
  }, [onBlur, open]);

  const handleOpen = useCallback(() => {
    if (value?.replace(/_+/g, '').trim()) {
      setOpen(true);
    }
  }, [value]);

  const requestNextItems = () => {
    requestItems?.(filter, 'get');
    setFilter({
      ...filter,
      offset: filter.offset + filter.limit,
    });
  };

  useEffect(() => {
    if (
      props.type === 'local' ||
      !open ||
      (debouncedInputValue !== '' &&
        debouncedInputValue?.length < minInputValueLength)
    ) {
      return;
    }

    requestItems?.(
      {
        ...filter,
        offset: 0,
        query: debouncedInputValue,
      },
      'search',
    ).then(() => {
      setFilter({
        ...filter,
        offset: filter.limit,
      });
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, debouncedInputValue]);

  const filterOptions = (
    optionList: string[],
    state: { inputValue: string },
  ) => {
    const cleanInput = state.inputValue.replace(/_/g, '').trim();

    return optionList.filter((optionElement: string) => {
      return optionElement.toLowerCase().includes(cleanInput.toLowerCase());
    });
  };

  return (
    <FormControl
      className={classNames(classes.suggestedInput, 'aqa_suggested_input')}
    >
      {label && <Label label={label} required={required} tooltip={tooltip} />}

      <Autocomplete
        classes={{
          paper: classes.list,
          option: classes.option,
        }}
        options={props.type === 'load' ? items : props.externalItems}
        filterOptions={filterOptions}
        fullWidth
        freeSolo
        renderTags={() => null}
        disabled={disabled || loading}
        disableClearable={!clearable}
        closeIcon={<CancelIcon color="disabled" />}
        inputValue={loading ? 'Загрузка...' : value}
        onInputChange={mask ? undefined : handleInputChange}
        onChange={handleChange}
        open={open}
        onOpen={handleOpen}
        onClose={handleBlur}
        renderOption={
          renderOption ? renderOption : (option) => <div>{option}</div>
        }
        ListboxComponent={
          ListBox as unknown as React.ComponentType<
            React.HTMLAttributes<HTMLElement>
          >
        }
        ListboxProps={{
          dataLength: items.length,
          next: requestNextItems,
          hasMore,
          loading: isItemsLoading,
          long: !!renderOption,
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
          return mask ? (
            <TextFieldMasked
              {...params}
              mask={mask}
              name={name}
              error={error}
              helperText={helperText}
              placeholder={placeholder || label}
              loading={loading}
              onChange={handleInputChange as never}
            />
          ) : (
            <TextField
              {...params}
              name={name}
              classes={{ root: classes.inputRoot }}
              autoComplete="off"
              variant="outlined"
              fullWidth
              error={error}
              helperText={helperText}
              placeholder={placeholder || label}
              {...(loading
                ? {
                    InputProps: {
                      endAdornment: <Preloader />,
                    },
                  }
                : {})}
            />
          );
        }}
      />
    </FormControl>
  );
};
