import React from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import {
  FormError,
  FormKnownValue,
  FormKnownValues,
  TextInput,
  Form,
} from '@vk-hr-tek/ui/form';
import { Link } from '@vk-hr-tek/ui/Link';
import { Redo } from '@vk-hr-tek/ui/Redo';

import { useDispatch, useSelector } from '@app/hooks';

import {
  selectNotification,
  selectPhone,
  selectStatus,
  selectSnils,
  verifyChangePasswordPhoneCode,
  changePasswordInit,
  redirectToSendInviteForPhone,
  setStage,
} from '../../../slice';
import { ChangePasswordDto, ChangePasswordInitDto } from '../../../dto';
import { Button } from '../../../ui/Button';
import { useGetAuthInstance } from '../../../hooks';

export const ChangePasswordVerifyCode = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const phone = useSelector(selectPhone);
  const snils = useSelector(selectSnils);
  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const authInstance = useGetAuthInstance();

  const { button_color: buttonColor } = authInstance;

  const onSubmit = ({ code }: ChangePasswordDto) => {
    new Promise((resolve) => {
      dispatch(
        verifyChangePasswordPhoneCode({
          values: { code, phone: phone as string },
          actions: { resolve },
        }),
      );
    });
  };

  const resendCode = () => {
    new Promise((resolve) => {
      dispatch(
        changePasswordInit({
          values: { phone, snils } as ChangePasswordInitDto,
          actions: { resolve },
        }),
      );
    });
  };

  return (
    <Form
      validate={(values) =>
        validator.validate(values, ChangePasswordDto, ['code-verify'])
      }
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormKnownValues>
            <FormKnownValue label="СНИЛС" value={snils} />
            <FormKnownValue
              label="Номер телефона"
              value={phone}
              verified={false}
            />
          </FormKnownValues>
          <Box mt="24">
            <TextInput
              name="code"
              label="СМС-код"
              placeholder="Введите СМС-код"
              type="number"
              inputMode="numeric"
              autocomplete="one-time-code"
              id="qaAuthChangePasswordVerifyCodeInput"
            />
          </Box>
          <Redo label="Отправить код повторно" action={resendCode} />
          <Box mt="24">
            <Button
              color={buttonColor && `#${buttonColor}`}
              disabled={pristine || hasValidationErrors}
              loading={submitting}
            >
              Подтвердить
            </Button>
          </Box>
          {!dirtySinceLastSubmit && status && status === 'failed' && (
            <FormError error={error} />
          )}
          <Box
            mt="8"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              onClick={() => {
                dispatch(setStage('init'));
              }}
              size="small"
              variant="tertiary"
              startIcon={<ArrowBackIcon />}
            >
              Вернуться назад
            </Link>
            <Link
              onClick={() => dispatch(redirectToSendInviteForPhone())}
              size="small"
              variant="tertiary"
            >
              Я сменил телефон
            </Link>
          </Box>
        </form>
      )}
    />
  );
};
