import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { useTheme } from '@vk-hr-tek/ui/Theme';
import { useInject } from '@vk-hr-tek/core/ioc';
import { Calendar } from '@vk-hr-tek/core/calendar';

import { useStyles } from './LogoEmptyIcon.styles';

type LogoIconVariants = '24' | '32' | '72' | '80';

type LogoIconProps = {
  variant?: LogoIconVariants;
};

const getSizeClass = (variant: LogoIconVariants, classes: ClassNameMap) => {
  switch (variant) {
    case '24':
      return classes.icon24;
    case '72':
      return classes.icon72;
    case '80':
      return classes.icon80;
    default:
      return classes.icon32;
  }
};

export const LogoEmptyIcon = ({ variant = '32' }: LogoIconProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const calendar = useInject(Calendar);

  const sizeClass = getSizeClass(variant, classes);

  if (theme === 'otp') {
    return (
      <SvgIcon className={sizeClass} htmlColor="#ffffff" viewBox="0 0 32 33">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 15.5122C0 7.24105 6.6983 0.515625 14.9966 0.515625V5.93935C9.70847 5.93935 5.42373 10.2241 5.42373 15.5122C5.42373 20.8004 9.70847 25.0851 14.9966 25.0851C20.2576 25.0851 24.5695 20.8275 24.5695 15.458H29.9932C29.9932 23.7834 23.2678 30.4817 14.9966 30.4817C6.72542 30.5088 0 23.8105 0 15.5122Z"
          fill="#006C3F"
        />
        <path
          d="M8.37891 15.5113C8.37891 11.8231 11.362 8.86719 15.023 8.86719C18.7111 8.86719 21.6942 11.8502 21.6942 15.5113C21.6942 19.1994 18.7111 22.1553 15.0501 22.1553C11.362 22.1824 8.37891 19.1994 8.37891 15.5113Z"
          fill="#50B848"
        />
        <path
          d="M19.6602 7.07764C19.6602 4.96238 21.3686 3.25391 23.4839 3.25391C25.5991 3.25391 27.3076 4.96238 27.3076 7.07764C27.3076 9.19289 25.5991 10.9014 23.4839 10.9014C21.3686 10.9014 19.6602 9.19289 19.6602 7.07764Z"
          fill="#006C3F"
        />
      </SvgIcon>
    );
  }

  if (calendar.isTimeForNewYear()) {
    return (
      <SvgIcon className={sizeClass} htmlColor="#ffffff" viewBox="0 0 32 33">
        <g clipPath="url(#clip0_17448_1759)">
          <g filter="url(#filter0_ii_17448_1759)">
            <path
              d="M0 15.3346C0 8.1045 0 4.4912 2.24734 2.2473C4.49468 0.00351024 8.10447 0 15.3346 0H16.6689C23.8955 0 27.5088 2.38419e-07 29.7562 2.2473C32.0035 4.4947 32 8.1045 32 15.3346V16.6689C32 23.8955 32 27.5088 29.7562 29.7562C27.5123 32.0035 23.8955 32 16.6689 32H15.3346C8.10447 32 4.49117 32 2.24734 29.7562C0.00351143 27.5123 0 23.8955 0 16.6689V15.3346Z"
              fill="#278CFF"
            />
          </g>
          <path
            d="M32 16.8907C31.9999 18.8471 31.9981 20.5348 31.9487 22H24.096C22.6623 22 21.9454 22 21.3978 21.721C20.9161 21.4755 20.5245 21.0839 20.279 20.6022C20 20.0546 20 19.3377 20 17.904V17.12C20 15.3278 20 14.4317 19.6512 13.7472C19.3444 13.1451 18.8549 12.6556 18.2528 12.3488C17.5683 12 16.6722 12 14.88 12H14.096C12.6623 12 11.9454 12 11.3978 11.721C10.9161 11.4755 10.5245 11.0839 10.279 10.6022C10 10.0546 10 9.33773 10 7.904V0.0520452C11.5208 0.000282974 13.2818 0 15.3346 0H16.6689C23.8955 0 27.5088 0 29.7562 2.2473C31.9765 4.46766 31.9998 8.01782 32 15.0752C32 15.15 32 15.2253 32 15.3009V16.6689C32 16.7432 32 16.8172 32 16.8907Z"
            fill="#00D9ED"
          />
          <path
            d="M6 23.76C6 24.9971 7.00288 26 8.24 26H13.135C14.3721 26 15.375 24.9971 15.375 23.76V18.865C15.375 17.6279 14.3721 16.625 13.135 16.625H8.24C7.00288 16.625 6 17.6279 6 18.865V23.76Z"
            fill="white"
          />
          <g filter="url(#filter1_dii_17448_1759)">
            <path
              d="M2.29245 2.18193C4.11499 0.567754 5.36741 -0.697809 24.6275 0.146707C28.9354 0.3356 30.5999 2.17542 31.4188 5.3143C31.4188 5.3143 30.2723 15.2425 26.5151 14.68C22.7579 14.1175 23.8024 2.42131 21.8609 2.42131C19.9194 2.42131 22.4894 9.44749 19.2982 9.24921C16.1071 9.05093 18.2474 3.28648 14.8136 2.91375C11.3798 2.54102 10.7671 9.14935 7.88173 8.9779C4.99634 8.80645 3.92128 7.34687 0.242188 7.23386C0.242188 7.23386 0.469902 3.79611 2.29245 2.18193Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_ii_17448_1759"
            x="-3"
            y="-3"
            width="38"
            height="38"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="3" dy="3" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.37 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_17448_1759"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-3" dy="-3" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_innerShadow_17448_1759"
              result="effect2_innerShadow_17448_1759"
            />
          </filter>
          <filter
            id="filter1_dii_17448_1759"
            x="0.242188"
            y="-1.12305"
            width="31.1758"
            height="16.8262"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.273706 0 0 0 0 0.198463 0 0 0 0 0.762789 0 0 0 0.18 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17448_1759"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17448_1759"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.20291 0 0 0 0 0.590009 0 0 0 0 0.977107 0 0 0 0.36 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_17448_1759"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.203922 0 0 0 0 0.588235 0 0 0 0 0.976471 0 0 0 0.28 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_innerShadow_17448_1759"
              result="effect3_innerShadow_17448_1759"
            />
          </filter>
          <clipPath id="clip0_17448_1759">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  }

  return (
    <SvgIcon className={sizeClass} htmlColor="#ffffff" viewBox="0 0 32 33">
      <mask
        id="mask0_5868_54827"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path
          d="M0 15.3346C0 8.10447 0 4.49117 2.24734 2.24734C4.49468 0.00351155 8.10447 0 15.3346 0H16.6689C23.8955 0 27.5088 0 29.7562 2.24734C32.0035 4.49468 32 8.10447 32 15.3346V16.6689C32 23.8955 32 27.5088 29.7562 29.7562C27.5123 32.0035 23.8955 32 16.6689 32H15.3346C8.10447 32 4.49117 32 2.24734 29.7562C0.00351155 27.5123 0 23.8955 0 16.6689V15.3346Z"
          fill="#212121"
        />
      </mask>
      <g mask="url(#mask0_5868_54827)">
        <path
          d="M0 15.3346C0 8.10447 0 4.49117 2.24734 2.24734C4.49468 0.00351155 8.10447 0 15.3346 0H16.6689C23.8955 0 27.5088 0 29.7562 2.24734C32.0035 4.49468 32 8.10447 32 15.3346V16.6689C32 23.8955 32 27.5088 29.7562 29.7562C27.5123 32.0035 23.8955 32 16.6689 32H15.3346C8.10447 32 4.49117 32 2.24734 29.7562C0.00351155 27.5123 0 23.8955 0 16.6689V15.3346Z"
          fill="#0077FF"
        />
        <path d="M32 0H10V12H20V22H32V0Z" fill="#00D9ED" />
        <path d="M6 26H15.375L15.375 16.625H6L6 26Z" fill="white" />
      </g>
    </SvgIcon>
  );
};
