import React from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import {
  FormError,
  FormKnownValue,
  FormKnownValues,
  TextInput,
  Form,
} from '@vk-hr-tek/ui/form';
import { Link } from '@vk-hr-tek/ui/Link';
import { Redo } from '@vk-hr-tek/ui/Redo';

import { useDispatch, useSelector } from '@app/hooks';

import {
  registerInit,
  selectNotification,
  selectPhone,
  selectSnils,
  selectStatus,
  verifyRegisterPhone,
  setStage,
} from '../../../slice';
import { RegisterDto, RegisterInitDto } from '../../../dto';
import { Button } from '../../../ui/Button';
import { useGetAuthInstance } from '../../../hooks';

export const RegisterVerifyCode = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const phone = useSelector(selectPhone);
  const snils = useSelector(selectSnils);
  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const authInstance = useGetAuthInstance();

  const { button_color: buttonColor } = authInstance;

  const onSubmit = (values: RegisterDto) =>
    new Promise(() => {
      dispatch(verifyRegisterPhone(values));
    });

  const resendCode = () => {
    new Promise((resolve) => {
      dispatch(
        registerInit({
          values: { phone } as RegisterInitDto,
          actions: { resolve },
        }),
      );
    });
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={(values) =>
          validator.validate(values, RegisterDto, ['code-verify'])
        }
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormKnownValues>
              <FormKnownValue label="СНИЛС" value={snils} />
              <FormKnownValue
                label="Номер телефона"
                value={phone}
                verified={false}
              />
            </FormKnownValues>
            <Box mt="24">
              <TextInput
                name="code"
                label="СМС-код"
                placeholder="Введите СМС-код"
                type="number"
                inputMode="numeric"
                autocomplete="one-time-code"
                id="qaAuthRegisterVerifyCodeInput"
              />
            </Box>
            <Redo label="Отправить код повторно" action={resendCode} />
            <Box mt="24">
              <Button
                color={buttonColor && `#${buttonColor}`}
                disabled={pristine || hasValidationErrors}
                loading={submitting}
              >
                Подтвердить
              </Button>
            </Box>
            {!dirtySinceLastSubmit && status && status === 'failed' && (
              <FormError error={error} />
            )}
            <Box mt="8">
              <Link
                onClick={() => {
                  dispatch(setStage('init'));
                }}
                size="small"
                variant="tertiary"
                startIcon={<ArrowBackIcon />}
              >
                Вернуться назад
              </Link>
            </Box>
          </form>
        )}
      />
    </div>
  );
};
