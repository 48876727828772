import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { VacationScheduleMyDeadlineResponse } from '@vk-hr-tek/app/app/gen/vacations';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Calendar } from '@vk-hr-tek/core/calendar';

import {
  CreateEventResponse as CreateResponse,
  CreateEventOptionsList,
  CreateEventResponse,
  CreateEventCommonOptionsResponse,
  CreateEventOptionsEventTypes,
  CreateEventTypeOptions,
  EventBatchListResponse,
  EventBatchEmployeesResponse,
  CreateEventTypeParticipantOptions,
  EmployeesList,
  EmployeeCreatableEventTypes,
} from '@app/gen/events';
import { AbsenceListResponse } from '@app/gen/absences';

import {
  CreateEventFromButtonDto,
  GetAbsenceListServiceDto,
  CreateEventFromAbsenceDto,
  GetDeadlineDto,
  GetOptionsEmployees,
  GetCreateEventTypeOptionsDto,
  GetEventBatchOptionsDto,
  CreateEventBatchDto,
  GetCreateEventTypeParticipantOptionsDto,
  GetCreatableEventTypesForEmployeeDto,
} from '../dto';

@injectable()
export class AbsencesService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private calendar: Calendar,
    private validator: ValidationService,
  ) {}

  async getAbsenceList(dto: GetAbsenceListServiceDto) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/absences/employee/list',
        {
          filters: {
            company_id: dto.companyId,
            year: dto.year,
          },
        },
        {
          withSide: true,
        },
      ),
      AbsenceListResponse,
    );

    return result.groups;
  }

  async getVacationScheduleDeadline(dto: GetDeadlineDto) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/vacation/schedule/${dto.companyId}/my_deadline`,
        {},
        { withSide: true },
      ),
      VacationScheduleMyDeadlineResponse,
    );

    return result;
  }

  async createEventFromAbsence({
    eventTypeId,
    employeeId,
    attributes,
  }: CreateEventFromAbsenceDto) {
    const response = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event',
        {
          event_type_id: eventTypeId,
          employee_id: employeeId,
          attributes,
        },
        { withSide: true },
      ),
      CreateEventResponse,
    );

    return {
      event_id: response.event_id,
    };
  }

  async getCreateOptions() {
    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options`,
        {
          tag: ['отсутствие'],
        },
        { withSide: true },
      ),
      CreateEventOptionsList,
    );
  }

  async createEvent(createEventDto: CreateEventFromButtonDto) {
    await this.validator.validateOrReject(
      createEventDto,
      CreateEventFromButtonDto,
      ['submit'],
    );

    const response = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event',
        {
          event_type_id: createEventDto.eventTypeId,
          ...(createEventDto.employeeId !== 'no_employee'
            ? {
                employee_id: createEventDto.employeeId,
              }
            : {}),
          ...(createEventDto.assigned_roles && {
            assigned_roles: createEventDto.assigned_roles,
          }),
          ...(createEventDto.eventTypeId && {
            event_type_id: createEventDto.eventTypeId,
          }),
          ...(createEventDto.parentEventId && {
            parent_event_id: createEventDto.parentEventId,
          }),
        },
        { withSide: true },
      ),
      CreateResponse,
    );

    return {
      event_id: response.event_id,
    };
  }

  async getCreateEventTypeOptions(
    getCreateEventTypeOptionsDto: GetCreateEventTypeOptionsDto,
  ) {
    await this.validator.validateOrReject(
      getCreateEventTypeOptionsDto,
      GetCreateEventTypeOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options/event_type`,
        {
          event_type_id: getCreateEventTypeOptionsDto.event_type_id,
        },
        {
          withSide: true,
        },
      ),
      CreateEventTypeOptions,
    );
  }

  async getExistingBatchEvents(getBatchEmployees: GetEventBatchOptionsDto) {
    await this.validator.validateOrReject(
      getBatchEmployees,
      GetEventBatchOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/batch',
        { company_id: getBatchEmployees.companyId },
        {
          withSide: true,
        },
      ),
      EventBatchListResponse,
    );
  }

  async getCreateBatchEmployees(getBatchEmployees: GetEventBatchOptionsDto) {
    await this.validator.validateOrReject(
      getBatchEmployees,
      GetEventBatchOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/batch/employees',
        {
          event_type_id: getBatchEmployees.eventTypeId,
          ...(getBatchEmployees.unitId
            ? { unit_id: getBatchEmployees.unitId }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      EventBatchEmployeesResponse,
    );
  }

  async createBatchEvents(createEventBatchDto: CreateEventBatchDto) {
    await this.validator.validateOrReject(
      createEventBatchDto,
      CreateEventBatchDto,
      ['default'],
    );

    await this.http.post(
      '/event/batch',
      {
        event_type_id: createEventBatchDto.eventTypeId,
        employee_ids: createEventBatchDto.employees,
        ...(createEventBatchDto.document
          ? { document: createEventBatchDto.document.value }
          : {}),
        ...(createEventBatchDto.eventBatchName
          ? { event_batch_name: createEventBatchDto.eventBatchName }
          : {}),
        ...(createEventBatchDto.documentDate && {
          document_date: this.calendar.formatISO(
            new Date(createEventBatchDto.documentDate),
            { representation: 'date' },
          ),
        }),
        ...(createEventBatchDto.documentNumber && {
          document_number: createEventBatchDto.documentNumber,
        }),
        ...(createEventBatchDto.assigned_roles && {
          assigned_roles: createEventBatchDto.assigned_roles,
        }),
        ...(createEventBatchDto.parentEventId && {
          parent_event_id: createEventBatchDto.parentEventId,
        }),
      },
      {
        withSide: true,
        isJson: false,
      },
    );
  }

  async getCreateEventTypeParticipantOptions(
    getCreateEventTypeParticipantOptionsDto: GetCreateEventTypeParticipantOptionsDto,
  ) {
    await this.validator.validateOrReject(
      getCreateEventTypeParticipantOptionsDto,
      GetCreateEventTypeParticipantOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options/event_type/participant_options`,
        {
          event_type_id: getCreateEventTypeParticipantOptionsDto.eventTypeId,
          role_id: getCreateEventTypeParticipantOptionsDto.roleId,
          ...(typeof getCreateEventTypeParticipantOptionsDto.limit === 'number'
            ? {
                limit: getCreateEventTypeParticipantOptionsDto.limit,
              }
            : {}),
          ...(getCreateEventTypeParticipantOptionsDto.offset
            ? {
                offset: getCreateEventTypeParticipantOptionsDto.offset,
              }
            : {}),
          ...(getCreateEventTypeParticipantOptionsDto.search
            ? {
                search: getCreateEventTypeParticipantOptionsDto.search,
              }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      CreateEventTypeParticipantOptions,
    );
  }

  async getCanCreateBatchEventOrEventForAnotherEmployee(dto: {
    companyId: string;
  }) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/common',
        {
          ...(dto?.companyId
            ? {
                company_id: dto.companyId,
              }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      CreateEventCommonOptionsResponse,
    );

    return result;
  }

  async getCreateBatchEventTypes(getOptionsEmployees: GetOptionsEmployees) {
    await this.validator.validateOrReject(
      getOptionsEmployees,
      GetOptionsEmployees,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/event_types',
        {
          company_id: getOptionsEmployees.companyId,
          ...(getOptionsEmployees.batchEnabled
            ? { batch_enabled: getOptionsEmployees.batchEnabled }
            : {}),
          no_employee: getOptionsEmployees.noEmployee,
          parent_event_id: getOptionsEmployees.parentEventId,
          tag: ['отсутствие'],
        },
        {
          withSide: true,
        },
      ),
      CreateEventOptionsEventTypes,
    );
  }

  async getEventEmployees(getOptionsEmployees: GetOptionsEmployees) {
    await this.validator.validateOrReject(
      getOptionsEmployees,
      GetOptionsEmployees,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/create_options/employees',
        {
          company_id: getOptionsEmployees.companyId,
          limit: getOptionsEmployees.limit,
          offset: getOptionsEmployees.offset,
          query: getOptionsEmployees.query,
          for_another: getOptionsEmployees.forAnother,
          event_type_group: getOptionsEmployees.eventTypeGroup,
          tag: ['отсутствие'],
        },
        {
          withSide: true,
        },
      ),
      EmployeesList,
    );
  }

  async getCreatableEventTypesForEmployee(
    getCreatableEventTypesForEmployeeDto: GetCreatableEventTypesForEmployeeDto,
  ) {
    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/create_options/employees/${getCreatableEventTypesForEmployeeDto.id}/event_types`,
        {
          ...(getCreatableEventTypesForEmployeeDto?.parentEventId
            ? {
                parent_event_id:
                  getCreatableEventTypesForEmployeeDto.parentEventId,
              }
            : {}),
          tag: ['отсутствие'],
        },
        {
          withSide: true,
        },
      ),
      EmployeeCreatableEventTypes,
    );
  }
}
