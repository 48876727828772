import { injectable } from 'inversify';

import { HttpService } from '../http';
import { ValidationService } from '../validation';
import { Calendar } from '../calendar';
import { UnmarshallerService } from '../unmarshaller';

import {
  GetUnitTypesResponse,
  GetUnitsTreeResponse,
  GetCompanyEmployeesResponse,
  GetUnitChildrenResponse,
  FilterSelectOption,
} from './gen/units';
import {
  GetCompanyUnitTreeDto,
  GetUnitTypesDto,
  GetCompanyUnitEmployeesDto,
  GetUnitTreeChildren,
  GetUnitTreeOptionsDto,
} from './dto';

@injectable()
export class UnitService {
  constructor(
    private validator: ValidationService,
    private calendar: Calendar,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getUnitTypes(getBatchEmployees: GetUnitTypesDto) {
    await this.validator.validateOrReject(getBatchEmployees, GetUnitTypesDto);

    const { companyId } = getBatchEmployees;

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/company/${companyId}/unit/types`,
        {},
        { withSide: true },
      ),
      GetUnitTypesResponse,
    );
  }

  async getUnitTree(getCompanyUnitTree: GetCompanyUnitTreeDto) {
    await this.validator.validateOrReject(
      getCompanyUnitTree,
      GetCompanyUnitTreeDto,
    );

    const { companyId, unitType, unitId } = getCompanyUnitTree;

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/company/${companyId}/unit/tree`,
        {
          ...(unitType && {
            unit_type: unitType,
          }),
          ...(unitId && {
            unit_id: unitId,
          }),
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      GetUnitsTreeResponse,
    );
  }

  async getUnitEmployees(getCompanyEmployees: GetCompanyUnitEmployeesDto) {
    await this.validator.validateOrReject(
      getCompanyEmployees,
      GetCompanyUnitEmployeesDto,
    );

    const {
      companyId,
      unitId,
      limit,
      offset = 0,
      query,
      includeForeignManagers,
      ids,
    } = getCompanyEmployees;

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/company/${companyId}/employees`,
        {
          ...(offset ? { offset } : {}),
          ...(unitId ? { unit_id: unitId } : {}),
          ...(limit ? { limit } : {}),
          ...(query ? { query } : {}),
          ...(ids ? { ids } : {}),
          ...(includeForeignManagers
            ? { include_foreign_managers: includeForeignManagers }
            : {}),
        },
        {
          withSide: true,
        },
      ),
      GetCompanyEmployeesResponse,
    );
  }

  async getUnitTreeChildren(dto: GetUnitTreeChildren) {
    await this.validator.validateOrReject(dto, GetUnitTreeChildren);

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/company/${dto.companyId}/unit/${dto.unitId}/children`,
        {},
        { withSide: true },
      ),
      GetUnitChildrenResponse,
    );

    return result;
  }

  async getUnitTreeOptions(getUnitTreeOptions: GetUnitTreeOptionsDto) {
    await this.validator.validateOrReject(
      getUnitTreeOptions,
      GetUnitTreeOptionsDto,
    );

    const result = await this.http.get('/units/options', getUnitTreeOptions, {
      withSide: true,
    });

    return result as FilterSelectOption[];
  }
}
