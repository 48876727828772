import {
  IsNotEmpty,
  IsUUID,
  IsString,
  IsOptional,
  IsArray,
  IsInt,
  IsBoolean,
} from 'class-validator';

export class GetCompanyUnitEmployeesDto {
  @IsUUID()
  @IsNotEmpty()
  companyId: string;

  @IsUUID()
  @IsOptional()
  unitId?: string;

  @IsString()
  @IsOptional()
  query?: string;

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsOptional()
  ids?: string[];

  @IsInt()
  @IsOptional()
  limit?: number;

  @IsInt()
  @IsOptional()
  offset?: number;

  @IsBoolean()
  @IsOptional()
  includeForeignManagers?: boolean = false;
}
