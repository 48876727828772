import React from 'react';

import { SvgIcon, useTheme } from '@material-ui/core';

export const VkBriefCaseIcon = () => {
  const theme = useTheme();

  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1667 6.50004H20.3333C21.2525 6.50004 22 7.2475 22 8.16668V10.6667C22 11.5859 21.2525 12.3334 20.3334 12.3334H13.6667V11.9167C13.6667 11.6864 13.4804 11.5 13.25 11.5H10.75C10.5197 11.5 10.3334 11.6864 10.3334 11.9167V12.3334H3.66668C2.74746 12.3334 2 11.5859 2 10.6667V8.16668C2 7.2475 2.74746 6.50004 3.66668 6.5H7.83332V5.66668C7.83332 4.74746 8.58082 4 9.5 4H14.5C15.4192 4 16.1667 4.7475 16.1667 5.66668V6.50004ZM9.5 6.50004H14.5V5.66668H9.5V6.50004Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
      <path
        d="M21.3318 12.8239C21.4572 12.7287 21.6269 12.7124 21.7689 12.7828C21.9105 12.8532 22 12.9976 22 13.1559V18.9998C22 19.9191 21.2525 20.6665 20.3334 20.6665H3.66668C2.74746 20.6665 2 19.919 2 18.9998V13.1559C2 12.9976 2.08953 12.8532 2.23109 12.7828C2.37312 12.7116 2.54238 12.7279 2.66813 12.8239C2.96438 13.0481 3.30941 13.1665 3.66668 13.1665H10.3333V14.4165C10.3333 14.6468 10.5197 14.8332 10.75 14.8332H13.25C13.4803 14.8332 13.6667 14.6468 13.6667 14.4165V13.1665H20.3333C20.6905 13.1665 21.0356 13.0481 21.3318 12.8239Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
    </SvgIcon>
  );
};
