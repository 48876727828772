import React, { ReactNode, forwardRef } from 'react';

import classNames from 'classnames';
import { Button as MUIButton } from '@material-ui/core';

import { Box } from '../Box';
import { CircularProgress } from '../CircularProgress';

import useStyles from './Button.styles';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiaryLight'
  | 'tertiaryDark'
  | 'tertiaryBlue'
  | 'quaternary'
  | 'quaternaryDark'
  | 'warning';

export type ButtonSize = 'large' | 'medium' | 'small' | 'extraSmall';

export interface ButtonProps {
  className?: string;
  id?: string;
  children?: React.ReactNode | React.ReactNode[];
  type?: 'button' | 'submit';
  disabled?: boolean;
  disableRipple?: boolean;
  icon?: ReactNode;
  startIcon?: ReactNode;
  fullWidth?: boolean;
  loading?: boolean;
  size?: ButtonSize;
  text?: string;
  variant?: ButtonVariant;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  testId?: string;
}

const muiVariants: Record<ButtonVariant, 'text' | 'outlined' | 'contained'> = {
  primary: 'contained',
  quaternary: 'contained',
  quaternaryDark: 'contained',
  warning: 'contained',
  secondary: 'outlined',
  tertiaryLight: 'text',
  tertiaryDark: 'text',
  tertiaryBlue: 'text',
};

const aqaClasses: Record<ButtonVariant, string> = {
  primary: 'aqa_button_primary',
  secondary: 'aqa_button_secondary',
  quaternary: 'aqa_button_quaternary',
  warning: 'aqa_button_warning',
  tertiaryDark: 'aqa_button_tertiaryDark',
  tertiaryBlue: 'aqa_button_tertiaryBlue',
  tertiaryLight: '',
  quaternaryDark: '',
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      type = 'button',
      disabled = false,
      disableRipple = false,
      icon,
      startIcon,
      fullWidth = false,
      loading = false,
      size = 'medium',
      variant = 'primary',
      onClick,
      testId,
      ...rest // we need this for tooltips
    },
    ref,
  ) => {
    const classes = useStyles();

    const buttonStyles = classNames(
      classes.button,
      classes[variant],
      icon &&
        !children &&
        `${classes.buttonIconOnly}${
          variant === 'primary' ? ` ${classes.buttonPrimaryIconOnly}` : ''
        }`,
      loading && `${classes.buttonLoading} buttonLoading`,
      className,
      'aqa_button',
      aqaClasses[variant],
    );

    const muiVariant: 'text' | 'outlined' | 'contained' =
      muiVariants[variant] || variant;

    return (
      <MUIButton
        data-testid={testId}
        color="primary"
        variant={muiVariant}
        className={buttonStyles}
        disabled={disabled}
        disableRipple={disableRipple}
        fullWidth={fullWidth}
        startIcon={startIcon}
        endIcon={icon}
        size={size === 'extraSmall' ? 'small' : size}
        type={type}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        <Box visibility={loading ? 'hidden' : 'visible'}>{children}</Box>
        {loading && (
          <CircularProgress
            size={size === 'extraSmall' ? 12.6 : 18.8}
            className={classes.loading}
            color="inherit"
          />
        )}
      </MUIButton>
    );
  },
);
