import { Type } from 'class-transformer';
import {
  IsIn,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  Min,
} from 'class-validator';

import { FilterType } from '@vk-hr-tek/core/filter';

const DEFAULT_LIMIT = 50;

export class GetCompanyAbsencesListDto {
  @IsInt()
  @Min(0)
  @Type(() => Number)
  @IsOptional()
  offset = 0;

  @IsInt()
  @Min(1)
  @Type(() => Number)
  @IsOptional()
  limit = DEFAULT_LIMIT;

  @IsUUID()
  @IsString()
  @IsNotEmpty()
  companyId: string;

  @IsIn(['month', 'year'])
  @IsString()
  @IsNotEmpty()
  calendarType? = 'month';

  @IsString()
  @IsOptional()
  month?: number;

  @IsString()
  @IsNotEmpty()
  year: number;

  filters?: FilterType;
}
