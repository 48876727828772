import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsMobile } from '@vk-hr-tek/ui/hooks';
import { selectAuthInstance } from '@vk-hr-tek/app/auth/slice';

import { useSelector } from '@app/hooks';

import { useLogout } from '../../../auth';
import { Page } from '../../ui/page';

export const CertificateManualVerifying = () => {
  const logout = useLogout();
  const isMobile = useIsMobile();

  const authInstance = useSelector(selectAuthInstance);

  return (
    <Page title="Ручная обработка ошибки" theme="notification">
      <Box display="flex" flexDirection="column" gap="32">
        <Typography
          variant={isMobile ? 'body3' : 'body2'}
          color="text.light.secondary"
          align="center"
        >
          При проверке ваших паспортных данных возникла ошибка выпуска
          электронной подписи.
        </Typography>
        <Typography
          variant={isMobile ? 'body3' : 'body2'}
          color="text.light.secondary"
          align="center"
        >
          Обращаем внимание, что обработка таких ошибок выполняется сотрудниками
          отдела кадров вашей компании.
        </Typography>
        <Typography
          variant={isMobile ? 'body3' : 'body2'}
          color="text.light.secondary"
          align="center"
        >
          О возможных способах решения проблемы можно прочитать подробнее
          в&nbsp;
          <Link href="mailto:support@hrtek.ru" size="small" variant="simple">
            Базе знаний
          </Link>
        </Typography>
      </Box>
      {authInstance.auth_type === 'pass' && (
        <Box mt="2">
          <Link onClick={logout} size="small" variant="tertiary">
            Выход к QR-коду
          </Link>
        </Box>
      )}
    </Page>
  );
};
