import React, { useCallback } from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import {
  FormError,
  FormKnownValue,
  FormKnownValues,
  TextInput,
  Form,
} from '@vk-hr-tek/ui/form';
import { Link } from '@vk-hr-tek/ui/Link';
import { Redo } from '@vk-hr-tek/ui/Redo';

import { useDispatch, useSelector } from '@app/hooks';

import {
  registerInitOAuth2,
  selectNotification,
  selectPhone,
  selectStatus,
  registerOAuth2,
  setStage,
} from '../../../slice';
import { RegisterOAuth2Dto, RegisterOAuth2InitDto } from '../../../dto';
import { Button } from '../../../ui/Button';
import { useGetAuthInstance } from '../../../hooks';

export const RegisterFinish = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const phone = useSelector(selectPhone);
  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const authInstance = useGetAuthInstance();

  const { button_color: buttonColor } = authInstance;

  const onSubmit = (values: RegisterOAuth2Dto) =>
    new Promise((resolve) => {
      dispatch(
        registerOAuth2({
          values,
          actions: { resolve },
        }),
      );
    });

  const handleReturnToInitForm = useCallback(
    () => dispatch(setStage('init')),
    [dispatch],
  );

  const resendCode = () => {
    new Promise((resolve) => {
      dispatch(
        registerInitOAuth2({
          values: { phone } as RegisterOAuth2InitDto,
          actions: { resolve },
        }),
      );
    });
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={(values) =>
          validator.validate(values, RegisterOAuth2Dto, ['code-verify'])
        }
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormKnownValues>
              <FormKnownValue
                label="Номер телефона"
                value={phone}
                verified={false}
              />
            </FormKnownValues>
            <Box mt="24">
              <TextInput
                name="code"
                label="СМС-код"
                placeholder="Введите СМС-код"
                type="text"
                id="qaAuthRegisterVerifyCodeInput"
              />
            </Box>
            <Redo label="Отправить код повторно" action={resendCode} />
            <Box mt="24">
              <Button
                color={buttonColor && `#${buttonColor}`}
                disabled={pristine || hasValidationErrors}
                loading={submitting}
              >
                Подтвердить
              </Button>
            </Box>
            {!dirtySinceLastSubmit && status && status === 'failed' && (
              <FormError error={error} />
            )}
            <Box mt="8">
              <Link
                onClick={handleReturnToInitForm}
                size="small"
                variant="tertiary"
                startIcon={<ArrowBackIcon />}
              >
                Вернуться назад
              </Link>
            </Box>
          </form>
        )}
      />
    </div>
  );
};
