import { useEffect } from 'react';

import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return createStyles({
    '@global': {
      body: {
        '-webkit-text-size-adjust': '100%',
        minWidth: 360,
        overflowX: 'hidden',
      },
      '.mac-scroll-styles': {
        '& *::-webkit-scrollbar, &::-webkit-scrollbar': {
          backgroundColor: theme.tokens.colors.bg.light.secondary, // TODO - fix нет в токенах
          width: '12px',
        },
        '& *::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb': {
          backgroundColor: '#babac0', // TODO - fix нет в токенах
          borderRadius: '8px',
          backgroundClip: 'content-box',
          border: '2px solid transparent',
        },
        '& *::-webkit-scrollbar-thumb:hover, &::-webkit-scrollbar-thumb:hover':
          {
            backgroundColor: '#a0a0a5', // TODO - fix нет в токенах
          },
        '& *::-webkit-scrollbar-button, &::-webkit-scrollbar-button': {
          display: 'none',
        },
      },
      pre: {
        display: 'inline',
        fontFamily: 'inherit',
        margin: theme.tokens.spacing[0],
      },
      'ul, ol': {
        listStyle: 'none',
        margin: theme.tokens.spacing[0],
        padding: theme.tokens.spacing[0],
      },

      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: theme.tokens.spacing[0],
      },

      'input[type=number]': {
        '-moz-appearance': 'textfield',
      },

      'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus':
        {
          backgroundColor: `${theme.tokens.colors.bg.light.primary} !important`,
          '-webkit-text-fill-color': 'rgb(80, 84, 99)', // TODO - fix нет в токенах
          '-webkit-box-shadow': `0 0 0px 1000px ${theme.tokens.colors.bg.light.primary} inset`,
        },
      '.nowrap': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '.webim-root': {
        '@media (min-width: 1024px)': {
          top: 'auto !important',
          left: 'auto !important',
          bottom: '137px',
          right: '48px',
        },
      },
      '.webim-dialogue': {
        borderBottom: 'none !important',
      },
      '.webim-root .webim-mobile-mode, .webim-root .webim-separate-mode': {
        top: '0 !important',
        left: '0 !important',
        bottom: '0 !important',
        right: '0 !important',
      },
      '.webim-powered-by-webim': {
        display: 'none',
      },
      '.webim-visitor-message span, .webim-visitor-message a, .webim-visitor-message .webim-file-size':
        {
          color: `${theme.tokens.colors.bg.light.primary} !important`,
        },
      '.webim-visitor-message .webim-file-size': {
        opacity: '.5',
      },
      '.webim-header': {
        background: `${theme.tokens.colors.original.brand.primary} !important`,
        paddingLeft: '90px !important', // TODO - fix нет в токенах
        cursor: 'default !important',
      },
      '.webim-header::after': {
        content: "''",
        position: 'absolute',
        left: '24px',
        top: '50%',
        transform: 'translate(0, -50%)',
        width: '32px',
        height: '32px',
        padding: `${theme.tokens.spacing[8]}px`,
        borderRadius: `${theme.tokens.radius.m}px`,
        background: `${theme.tokens.colors.bg.light.primary} url(/icons/vk-logo-icon.svg) no-repeat center`,
      },
      '.webim-notification-alert .webim-header::after': {
        display: 'none',
      },
      '.webim-root .webim-operator-block > .webim-operator': {
        marginLeft: `${theme.tokens.spacing[0]} !important`,
      },
      '.webim-root .webim-no-operator-block': {
        paddingLeft: `${theme.tokens.spacing[0]} !important`,
      },
      '.webim_button': {
        background: `${theme.tokens.colors.bg.light.primary} url(/icons/${theme.tokens.name}-icon-chat.svg) 50% 50% no-repeat !important`,
        borderRadius: `${theme.tokens.radius.l}px !important`,
        border: 'none !important',
        color: theme.tokens.colors.bg.light.primary,
        display: 'none !important',
        '@media (min-width: 1024px)': {
          display: 'block !important',
          right: '48px !important',
          bottom: '58px !important',
          width: '72px !important',
          height: '72px !important',
        },
      },
      '.webim_button_no_loaded': {
        position: 'fixed',
        cursor: 'pointer',
        zIndex: '101 !important',
        background: theme.palette.background.paper,
        border: '1px solid #e2e2ea',
        boxSizing: 'border-box',
        boxShadow:
          '0 2px 8px rgba(0, 26, 77, 0.1), 0 6px 28px rgba(0, 26, 77, 0.12)',
        borderRadius: '16px',
        padding: '12px !important',
      },
      '.webim_button_loading': {
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '101 !important',
        background: `${theme.palette.background.paper} !important`,
        pointerEvents: 'none',
        cursor: 'default',

        '& span': {
          maxHeight: '30px',
        },
      },
      '.webim_button img, .webim-avatar, .webim_button.hidden, .webim-message-body:after, .webim-message div[data-webim-region="button-rows"]':
        {
          display: 'none !important',
        },
      '.webim-form-control textarea, .webim-root .webim-dialogue-message textarea, .webim-root .webim-dialogue-message .webim-textarea-ext':
        {
          fontSize: '16px !important', // TODO - fix нет в токенах
        },
      '.webim-popups-block': {
        paddingTop: '7px !important', // TODO - fix нет в токенах
      },
      '.webim_invitediv': {
        zIndex: '100 !important',
      },
      '.webim-desktop-mode.webim-chat-button': {
        zIndex: '99 !important',
      },
      '.webim-button-corner': {
        background: theme.tokens.colors.bg.light.primary,
        border: `${theme.tokens.border.s}px solid #e2e2ea`, // TODO - fix нет в токенах
        boxSizing: 'border-box',
        zIndex: '99 !important',
        boxShadow:
          '0 2px 8px rgba(0, 26, 77, 0.1), 0 6px 28px rgba(0, 26, 77, 0.12)', // TODO - fix нет в токенах
        borderRadius: `${theme.tokens.radius.l}px`,
        padding: `${theme.tokens.spacing[12]}px !important`,
      },
      '.webim-button-corner-button': {
        bottom: '92px !important',
      },
      '.webim-button-corner-right': {
        right: '520px !important',
      },
      '.webim-chat-button': {
        bottom: '174px !important',
      },
      '.webim-chat-right': {
        right: '520px !important',
      },
      '.webim-button-row': {
        flexWrap: 'wrap',
        flexDirection: 'row-reverse',
      },
      '.webim-button-row .webim-btn': {
        whiteSpace: 'nowrap !important',
        flexGrow: '0 !important',
        flexBasis: 'auto !important',
        margin: `${theme.tokens.spacing[0]} ${theme.tokens.spacing[4]}px 10px`, // TODO - fix нет в токенах
        background: `${theme.tokens.colors.bg.light.primary} !important`,
        border: `${theme.tokens.border.m}px solid #115cef`, // TODO - fix нет в токенах
        boxSizing: 'border-box',
        borderRadius: `${theme.tokens.radius.l}px`,
      },
      '.webim-button-row .webim-btn span': {
        color: `${theme.tokens.colors.bg.light.primary} !important`,
      },
      '.webim-chat-block': {
        marginRight: `${theme.tokens.spacing[0]} !important`,
      },
      '.webim-control-block': {
        position: 'relative !important',
        zIndex: '1000 !important',
      },
      '.webim-root.webim-desktop-mode .webim-control-block': {
        position: 'absolute !important',
        bottom: '-84px',
        right: '51px',
      },
      '.webim-root.webim-mobile-mode .webim-control-block': {
        position: 'absolute !important',
        top: '50%',
        right: '10px',
        transform: 'translateY(-50%)',
      },
      '.webim-root.webim-desktop-mode .webim-control-block .webim-action-close':
        {
          padding: `${theme.tokens.spacing[12]}px`,
          width: '48px !important',
          height: '48px !important',
          background: `${theme.tokens.colors.bg.light.primary} url(/icons/${theme.tokens.name}-icon-chat-close.svg) 50% 50% no-repeat`,
          borderRadius: `${theme.tokens.radius.l}px !important`,
          border: 'none !important',
          color: theme.tokens.colors.bg.light.primary,
          fontSize: '18px', // TODO - fix нет в токенах
        },
      '.webim-root.webim-desktop-mode > .webim-control-block .webim-ico.webim-ico-close':
        {
          opacity: 0,
        },
      '.webim-message-body': {
        borderRadius: `${theme.tokens.radius.l}px ${theme.tokens.radius.l}px ${theme.tokens.radius[0]}px ${theme.tokens.radius.l}px !important`,
      },
      '.webim-message-body[data-webim-custom-style="messageVisitor"]': {
        background: `${theme.tokens.colors.original.brand.primary} !important`,
      },
      '.webim-message-body[data-webim-custom-style="messageVisitor"] span': {
        color: `${theme.tokens.colors.bg.light.primary} !important`,
      },
      '.webim-message-body[data-webim-custom-style="messageVisitor"] a, .webim-file-text':
        {
          color: `${theme.tokens.colors.bg.light.primary} !important`,
        },
      '.webim-message:last-child div[data-webim-region="button-rows"]': {
        display: 'block',
      },
      '.webim-btn': {
        background: `${theme.tokens.colors.original.brand.primary} !important`,
      },
      '.webim-root .webim-send-button .webim-ico.webim-ico-send': {
        color: theme.tokens.colors.original.brand.primary,
      },
      '.webim-root .webim-offline-block .webim-description': {
        paddingTop: `${theme.tokens.spacing[20]}px`,
        background: '#f1f1ff !important', // TODO - fix нет в токенах
        color: `${theme.tokens.colors.bg.light.primary}!important`,
      },
      ':root': {
        '--react-pdf-annotation-layer': '1',
        '--annotation-unfocused-field-background':
          "url(\"data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>\")",
        '--input-focus-border-color': 'Highlight',
        '--input-focus-outline': '1px solid Canvas',
        '--input-unfocused-border-color': 'transparent',
        '--input-disabled-border-color': 'transparent',
        '--input-hover-border-color': 'black',
        '--link-outline': 'none',

        '--react-pdf-text-layer': '1',
        '--highlight-bg-color': 'rgba(180, 0, 170, 1)', // TODO - fix нет в токенах
        '--highlight-selected-bg-color': 'rgba(0, 100, 0, 1)', // TODO - fix нет в токенах
      },
      '@media screen and (forced-colors: active)': {
        ':root': {
          '--input-focus-border-color': 'CanvasText',
          '--input-unfocused-border-color': 'ActiveText',
          '--input-disabled-border-color': 'GrayText',
          '--input-hover-border-color': 'Highlight',
          '--link-outline': '1.5px solid LinkText',
          '--highlight-bg-color': 'Highlight',
          '--highlight-selected-bg-color': 'ButtonText',
        },
        '.annotationLayer .textWidgetAnnotation :is(input, textarea):required,\n  .annotationLayer .choiceWidgetAnnotation select:required,\n  .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required':
          {
            outline: '1.5px solid selectedItem',
          },
        '.annotationLayer .linkAnnotation:hover': {
          backdropFilter: 'invert(100%)',
        },
      },
      '.annotationLayer': {
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none',
        transformOrigin: '0 0',
        zIndex: 3,
      },
      ".annotationLayer[data-main-rotation='90'] .norotate": {
        transform: 'rotate(270deg) translateX(-100%)',
      },
      ".annotationLayer[data-main-rotation='180'] .norotate": {
        transform: 'rotate(180deg) translate(-100%, -100%)',
      },
      ".annotationLayer[data-main-rotation='270'] .norotate": {
        transform: 'rotate(90deg) translateY(-100%)',
      },
      '.annotationLayer canvas': {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      '.annotationLayer section': {
        position: 'absolute',
        textAlign: 'initial',
        pointerEvents: 'auto',
        boxSizing: 'border-box',
        margin: theme.tokens.spacing[0],
        transformOrigin: '0 0',
      },
      '.annotationLayer .linkAnnotation': { outline: 'var(--link-outline)' },
      '.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a':
        {
          position: 'absolute',
          fontSize: '1em', // TODO - fix нет в токенах
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
        },
      '.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a:hover':
        {
          opacity: 0.2,
          background: 'rgba(255, 255, 0, 1)', // TODO - fix нет в токенах
          boxShadow: '0 2px 10px rgba(255, 255, 0, 1)', // TODO - fix нет в токенах
        },
      '.annotationLayer .textAnnotation img': {
        position: 'absolute',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
      },
      '.annotationLayer .textWidgetAnnotation :is(input, textarea),\n.annotationLayer .choiceWidgetAnnotation select,\n.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input':
        {
          backgroundImage: 'var(--annotation-unfocused-field-background)',
          border: `${theme.tokens.border.m}px solid var(--input-unfocused-border-color)`,
          boxSizing: 'border-box',
          font: 'calc(9px * var(--scale-factor)) sans-serif',
          height: '100%',
          margin: theme.tokens.spacing[0],
          verticalAlign: 'top',
          width: '100%',
        },
      '.annotationLayer .textWidgetAnnotation :is(input, textarea):required,\n.annotationLayer .choiceWidgetAnnotation select:required,\n.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required':
        {
          outline: '1.5px solid red',
        },
      '.annotationLayer .choiceWidgetAnnotation select option': {
        padding: theme.tokens.spacing[0],
      },
      '.annotationLayer .buttonWidgetAnnotation.radioButton input': {
        borderRadius: theme.tokens.radius.round,
      },
      '.annotationLayer .textWidgetAnnotation textarea': { resize: 'none' },
      '.annotationLayer .textWidgetAnnotation :is(input, textarea)[disabled],\n.annotationLayer .choiceWidgetAnnotation select[disabled],\n.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input[disabled]':
        {
          background: 'none',
          border: `${theme.tokens.border.m}px solid var(--input-disabled-border-color)`,
          cursor: 'not-allowed',
        },
      '.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,\n.annotationLayer .choiceWidgetAnnotation select:hover,\n.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:hover':
        {
          border: `${theme.tokens.border.m}px solid var(--input-hover-border-color)`,
        },
      '.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,\n.annotationLayer .choiceWidgetAnnotation select:hover,\n.annotationLayer .buttonWidgetAnnotation.checkBox input:hover':
        {
          borderRadius: '2px', // TODO - fix нет в токенах
        },
      '.annotationLayer .textWidgetAnnotation :is(input, textarea):focus,\n.annotationLayer .choiceWidgetAnnotation select:focus':
        {
          background: 'none',
          border: `${theme.tokens.border.m}px solid var(--input-focus-border-color)`,
          borderRadius: '2px', // TODO - fix нет в токенах
          outline: 'var(--input-focus-outline)',
        },
      '.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus':
        {
          backgroundImage: 'none',
          backgroundColor: 'transparent',
        },
      '.annotationLayer .buttonWidgetAnnotation.checkBox :focus': {
        border: `${theme.tokens.border.m}px solid var(--input-focus-border-color)`,
        borderRadius: '2px', // TODO - fix нет в токенах
        outline: 'var(--input-focus-outline)',
      },
      '.annotationLayer .buttonWidgetAnnotation.radioButton :focus': {
        border: `${theme.tokens.border.m}px solid var(--input-focus-border-color)`,
        outline: 'var(--input-focus-outline)',
      },
      '.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,\n.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,\n.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before':
        {
          backgroundColor: 'CanvasText',
          content: "''",
          display: 'block',
          position: 'absolute',
        },
      '.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,\n.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after':
        {
          height: '80%',
          left: '45%',
          width: '1px',
        },
      '.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before':
        {
          transform: 'rotate(45deg)',
        },
      '.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after':
        {
          transform: 'rotate(-45deg)',
        },
      '.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before':
        {
          borderRadius: theme.tokens.radius.round,
          height: '50%',
          left: '30%',
          top: '20%',
          width: '50%',
        },
      '.annotationLayer .textWidgetAnnotation input.comb': {
        fontFamily: 'monospace',
        paddingLeft: `${theme.tokens.spacing[2]}px`,
        paddingRight: theme.tokens.spacing[0],
      },
      '.annotationLayer .textWidgetAnnotation input.comb:focus': {
        width: '103%',
      },
      '.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input':
        {
          appearance: 'none',
        },
      '.annotationLayer .popupTriggerArea': { height: '100%', width: '100%' },
      '.annotationLayer .fileAttachmentAnnotation .popupTriggerArea': {
        position: 'absolute',
      },
      '.annotationLayer .popupWrapper': {
        position: 'absolute',
        fontSize: 'calc(9px * var(--scale-factor))',
        width: '100%',
        minWidth: 'calc(180px * var(--scale-factor))',
        pointerEvents: 'none',
      },
      '.annotationLayer .popup': {
        position: 'absolute',
        maxWidth: 'calc(180px * var(--scale-factor))',
        backgroundColor: 'rgba(255, 255, 153, 1)', // TODO - fix нет в токенах
        boxShadow:
          '0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor))\n    rgba(136, 136, 136, 1)', // TODO - fix нет в токенах
        borderRadius: 'calc(2px * var(--scale-factor))',
        padding: 'calc(6px * var(--scale-factor))',
        marginLeft: 'calc(5px * var(--scale-factor))',
        cursor: 'pointer',
        font: 'message-box',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        pointerEvents: 'auto',
      },
      '.annotationLayer .popup > *': {
        fontSize: 'calc(9px * var(--scale-factor))',
      },
      '.annotationLayer .popup h1': { display: 'inline-block' },
      '.annotationLayer .popupDate': {
        display: 'inline-block',
        marginLeft: 'calc(5px * var(--scale-factor))',
      },
      '.annotationLayer .popupContent': {
        borderTop: `${theme.tokens.border.s}px solid rgba(51, 51, 51, 1)`, // TODO - fix нет в токенах
        marginTop: 'calc(2px * var(--scale-factor))',
        paddingTop: 'calc(2px * var(--scale-factor))',
      },
      '.annotationLayer .richText > *': {
        whiteSpace: 'pre-wrap',
        fontSize: 'calc(9px * var(--scale-factor))',
      },
      '.annotationLayer .highlightAnnotation,\n.annotationLayer .underlineAnnotation,\n.annotationLayer .squigglyAnnotation,\n.annotationLayer .strikeoutAnnotation,\n.annotationLayer .freeTextAnnotation,\n.annotationLayer .lineAnnotation svg line,\n.annotationLayer .squareAnnotation svg rect,\n.annotationLayer .circleAnnotation svg ellipse,\n.annotationLayer .polylineAnnotation svg polyline,\n.annotationLayer .polygonAnnotation svg polygon,\n.annotationLayer .caretAnnotation,\n.annotationLayer .inkAnnotation svg polyline,\n.annotationLayer .stampAnnotation,\n.annotationLayer .fileAttachmentAnnotation':
        {
          cursor: 'pointer',
        },
      '.annotationLayer section svg': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
      },
      '.annotationLayer .annotationTextContent': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0,
        color: 'transparent',
        userSelect: 'none',
        pointerEvents: 'none',
      },
      '.annotationLayer .annotationTextContent span': {
        width: '100%',
        display: 'inline-block',
      },
      "[data-main-rotation='90']": {
        transform: 'rotate(90deg) translateY(-100%)',
      },
      "[data-main-rotation='180']": {
        transform: 'rotate(180deg) translate(-100%, -100%)',
      },
      "[data-main-rotation='270']": {
        transform: 'rotate(270deg) translateX(-100%)',
      },
      '.textLayer': {
        position: 'absolute',
        textAlign: 'initial',
        inset: '0',
        overflow: 'hidden',
        lineHeight: 1,
        textSizeAdjust: 'none',
        forcedColorAdjust: 'none',
        transformOrigin: '0 0',
        zIndex: 2,
      },
      '.textLayer :is(span, br)': {
        color: 'transparent',
        position: 'absolute',
        whiteSpace: 'pre',
        cursor: 'text',
        margin: theme.tokens.spacing[0],
        transformOrigin: '0 0',
      },
      '.textLayer span.markedContent': { top: '0', height: '0' },
      '.textLayer .highlight': {
        margin: '-1px', // TODO - fix нет в токенах
        padding: '1px', // TODO - fix нет в токенах
        backgroundColor: 'var(--highlight-bg-color)',
        borderRadius: `${theme.tokens.radius.xs}px`,
      },
      '.textLayer .highlight.appended': { position: 'initial' },
      '.textLayer .highlight.begin': {
        borderRadius: `${theme.tokens.radius.xs}px ${theme.tokens.radius[0]} ${theme.tokens.radius[0]} ${theme.tokens.radius.xs}px`,
      },
      '.textLayer .highlight.end': {
        borderRadius: `${theme.tokens.radius[0]} ${theme.tokens.radius.xs}px ${theme.tokens.radius.xs}px ${theme.tokens.radius[0]}`,
      },
      '.textLayer .highlight.middle': {
        borderRadius: theme.tokens.radius[0],
      },
      '.textLayer .highlight.selected': {
        backgroundColor: 'var(--highlight-selected-bg-color)',
      },
      '.textLayer br::selection': { background: 'transparent' },
      '.textLayer .endOfContent': {
        display: 'block',
        position: 'absolute',
        inset: '100% 0 0',
        zIndex: -1,
        cursor: 'default',
        userSelect: 'none',
      },
      '.textLayer .endOfContent.active': { top: '0' },
      '.mailtoLink': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  });
});

export const GlobalStyles = () => {
  useEffect(() => {
    const isWindows = navigator.userAgent.indexOf('Win') != -1;
    const isLinux = navigator.userAgent.indexOf('Linux') != -1;

    const isWinOrLin = isWindows || isLinux;

    if (isWinOrLin) {
      document.body.classList.add('mac-scroll-styles');
    }
  }, []);

  useStyles();

  return null;
};
