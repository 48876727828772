import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Link } from '@vk-hr-tek/ui/Link';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks/media';

export const Footer = () => {
  const isDesktop = useIsDesktop();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={isDesktop ? 'center' : 'flex-start'}
      px={{ md: '48', xs: '16' }}
      py="16"
      bgcolor="bg.greyscale.primary"
      borderColor="stroke.primary"
      border={1}
      borderRight={0}
      borderBottom={0}
      borderLeft={0}
      zIndex={100}
    >
      <Box>
        <Typography variant="caption" color="text.light.tertirary">
          &copy;
          {`${currentYear} VK`}
        </Typography>
      </Box>
      <Box
        display="flex"
        gap={isDesktop ? '16' : '8'}
        flexDirection={isDesktop ? 'row' : 'column'}
      >
        <Link
          href={`${window.REACT_APP_VKHRTEK_API_URL}/docs/user_agreement`}
          stroke={false}
          variant="tertiary"
          size="extraSmall"
          target="_blank"
        >
          Пользовательское соглашение
        </Link>

        <Link
          href={`${window.REACT_APP_VKHRTEK_API_URL}/docs/policy_confidentiality`}
          stroke={false}
          variant="tertiary"
          size="extraSmall"
          target="_blank"
        >
          Политика конфиденциальности
        </Link>
      </Box>
    </Box>
  );
};
