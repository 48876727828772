import { injectable } from 'inversify';

export enum GoalsEnum {
  useFilter = 'useFilter',
  openLink = 'openLink',
  copyToClipboard = 'copyToClipboard',
  selectItems = 'selectItems',
  downloadItems = 'downloadItems',
}

interface ParamsType {
  page: string;
  block: string;
  element: string;
  actionType: string;
}

interface MyTrackerParamsType {
  id?: string;
  type: 'setUserID' | 'reachGoal' | 'pageView';
  userid?: string;
  goal?: GoalsEnum;
  params?: ParamsType;
  start?: number;
}

type Window = typeof window;
type MyTrackerWindow = Window & {
  _tmr: {
    push: (params: MyTrackerParamsType) => void;
  };
};

@injectable()
export class AnalyticService {
  private readonly tmr = (window as MyTrackerWindow)._tmr;

  setUser(id: string) {
    if (window.REACT_APP_VKHRTEK_MY_TRACKER_ID) {
      this.tmr?.push({ type: 'setUserID', userid: id });
    }
  }

  pageView(userId: string) {
    if (window.REACT_APP_VKHRTEK_MY_TRACKER_ID) {
      this.tmr?.push({
        id: window.REACT_APP_VKHRTEK_MY_TRACKER_ID,
        type: 'pageView',
        start: new Date().getTime(),
        userid: userId,
      });
    }
  }

  registerAction(goal: GoalsEnum, params: ParamsType) {
    if (window.REACT_APP_VKHRTEK_MY_TRACKER_ID) {
      this.tmr?.push({
        id: window.REACT_APP_VKHRTEK_MY_TRACKER_ID,
        type: 'reachGoal',
        goal,
        params,
      });
    }
  }
}
